import { MenuItems } from '../enums/side-menu.enum';
import { UserRole } from '../enums/user-role.enum';
import { RouteConfig } from '../interfaces/routes-config.interface';
// tslint:disable: no-console
if (process.env.NODE_ENV === 'development') {
  console.group();
  console.info('*** PUBLIC PATH *** ', process.env.PUBLIC_URL);
  console.info('*** BE ROUTE ***', process.env.REACT_APP_BE_API_ENDPOINT);
  console.groupEnd();
}
// tslint:enable: no-console
export const PUBLIC_PATH = process.env.PUBLIC_URL || '';
export const SERVER_BASE_URL = process.env.REACT_APP_BE_API_ENDPOINT;
export const HOST_NAME = '//' + window.location.host.replace(/\/+$/, '');
export const WEBSITE_HOME = process.env.REACT_APP_WEBSITE_BASE || 'https://carofi.app';
export const PARTNER_PORTAL_URL =
  process.env.REACT_APP_PARTNER_PORTAL_BASE || 'https://partner.carofi.app';
import { MenuIcons } from './menu-icons';

export const APP_ROUTES: RouteConfig = {
  AUTH_MODULE: {
    PATH: '/auth',
    FULL_PATH: '/auth',
    NAME: 'auth',
    IS_MENU: false,

    CHILDREN: {
      LOGIN: {
        PATH: '/login',
        FULL_PATH: '/auth/login',
        NAME: 'Sign in',
        IS_MENU: false
      },
      RESET_PASSWORD: {
        PATH: '/reset-password',
        FULL_PATH: '/auth/reset-password',
        NAME: 'Reset password',
        IS_MENU: false
      },
      FORGOT_PASSWORD: {
        PATH: '/forgot-password',
        FULL_PATH: '/auth/forgot-password',
        NAME: 'Forgot password',
        IS_MENU: false
      },
      UNAUTHORIZED: {
        PATH: '/unauthorized',
        FULL_PATH: '/auth/unauthorzied',
        NAME: 'Unauthorized',
        IS_MENU: false
      }
    }
  },
  DASHBOARD_MODULE: {
    PATH: '/dashboard',
    FULL_PATH: '/dashboard',
    DISPLAY_NAME: 'DASHBOARD',
    NAME: 'dashboard',
    IS_MENU: true,
    PERMITTED_ROLES: [
      UserRole.DIGITAL_SALES_AGENT,
      UserRole.DIGITAL_SALES_SENIOR_AGENT,
      UserRole.DIGITAL_SALES_LEAD,
      UserRole.DIGITAL_SALES_MANAGER,
      UserRole.RETAIL_SALES_AGENT,
      UserRole.RETAIL_SALES_SENIOR_AGENT,
      UserRole.RETAIL_SALES_LEAD,
      UserRole.RETAIL_SALES_MANAGER,
      UserRole.CORPORATE_SALES_AGENT,
      UserRole.CORPORATE_SALES_SENIOR_AGENT,
      UserRole.CORPORATE_SALES_LEAD,
      UserRole.CORPORATE_SALES_MANAGER,
      UserRole.E_COMMERCE_FINANCE,
      UserRole.E_COMMERCE_AUTO,
      UserRole.E_COMMERCE_MANAGER,
      UserRole.FINANCE_OFFICER,
      UserRole.OPERATION_MANAGER,
      UserRole.ADMIN
    ],
    ICON: MenuIcons.DASHBOARD_MODULE,
    CHILDREN: {
      HOME: {
        PATH: '/home',
        FULL_PATH: '/dashboard/home',
        NAME: 'Home',
        IS_MENU: false
      }
    }
  },
  COMMUNICATION_MODULE: {
    PATH: '/communication',
    FULL_PATH: '/communication',
    DISPLAY_NAME: 'Corporate Whatsapp',
    NAME: 'communication',
    IS_MENU: true,
    PERMITTED_ROLES: [
      UserRole.ADMIN,
      UserRole.E_COMMERCE_MANAGER,
      UserRole.E_COMMERCE_FINANCE,
      UserRole.E_COMMERCE_MANAGER,
      UserRole.DIGITAL_SALES_AGENT,
      UserRole.DIGITAL_SALES_SENIOR_AGENT,
      UserRole.DIGITAL_SALES_LEAD,
      UserRole.DIGITAL_SALES_MANAGER,
      UserRole.RETAIL_SALES_AGENT,
      UserRole.RETAIL_SALES_SENIOR_AGENT,
      UserRole.RETAIL_SALES_LEAD,
      UserRole.RETAIL_SALES_MANAGER,
      UserRole.CORPORATE_SALES_AGENT,
      UserRole.CORPORATE_SALES_SENIOR_AGENT,
      UserRole.CORPORATE_SALES_LEAD,
      UserRole.CORPORATE_SALES_MANAGER
    ],
    PARENT: MenuItems.COMMUNICATION,
    CHILDREN: {
      CORPORATE_WHATSAPP: {
        PATH: '/communication/corporate-whatsapp',
        FULL_PATH: '/communication/corporate-whatsapp',
        NAME: 'Corporate Whatsapp',
        IS_MENU: true
      }
    }
  },
  TRANSACTIONS_MODULE: {
    PATH: '/transactions',
    FULL_PATH: '/transactions',
    DISPLAY_NAME: 'Transactions',
    NAME: 'transactions',
    IS_MENU: true,
    PARENT: MenuItems.OPERATIONS,
    ICON: MenuIcons.OPERATIONS,
    PERMITTED_ROLES: [UserRole.ADMIN, UserRole.FINANCE_OFFICER],
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/transactions/list',
        NAME: 'List',
        IS_MENU: true
      },
      DETAILS: {
        PATH: '/transactions/details',
        FULL_PATH: '/transactions/details/:id',
        NAME: 'List',
        IS_MENU: false
      },
      ADD: {
        PATH: '/add',
        FULL_PATH: '/transactions/add',
        NAME: 'Add',
        IS_MENU: false
      },
      EDIT: {
        PATH: '/transactions/edit',
        FULL_PATH: '/transactions/edit/:id',
        NAME: 'edit',
        IS_MENU: false
      }
    }
  },
  PURCHASES_MODULE: {
    PATH: '/purchase-orders',
    FULL_PATH: '/purchase-orders',
    DISPLAY_NAME: 'Purchase orders',
    NAME: 'purchase-orders',
    IS_MENU: true,
    PARENT: MenuItems.OPERATIONS,
    ICON: MenuIcons.OPERATIONS,
    PERMITTED_ROLES: [UserRole.ADMIN, UserRole.FINANCE_OFFICER],
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/purchase-orders/list',
        NAME: 'List',
        IS_MENU: true
      },
      DETAILS: {
        PATH: '/purchase-orders/details',
        FULL_PATH: '/purchase-orders/details/:id',
        NAME: 'List',
        IS_MENU: false
      },
      ADD: {
        PATH: '/add',
        FULL_PATH: '/purchase-orders/add',
        NAME: 'Add',
        IS_MENU: false
      },
      EDIT: {
        PATH: '/purchase-orders/edit',
        FULL_PATH: '/purchase-orders/edit/:id',
        NAME: 'edit',
        IS_MENU: false
      }
    }
  },
  // CONTRACTS_MODULE: {
  //   PATH: '/contracts',
  //   FULL_PATH: '/contracts',
  //   DISPLAY_NAME: 'Contracts',
  //   NAME: 'contracts',
  //   IS_MENU: true,
  //   PARENT: MenuItems.OPERATIONS,
  //   CHILDREN: {
  //     LIST: {
  //       PATH: '/list',
  //       FULL_PATH: '/contracts/list',
  //       NAME: 'List',
  //       IS_MENU: false
  //     },
  //     EDIT: {
  //       PATH: '/edit',
  //       FULL_PATH: '/contracts/edit',
  //       NAME: 'Edit',
  //       IS_MENU: false,
  //       NEW_LABEL: true
  //     }
  //   }
  // },
  // CHEQUES_MODULE: {
  //   PATH: '/cheques',
  //   FULL_PATH: '/cheques',
  //   DISPLAY_NAME: 'Cheques',
  //   NAME: 'cheques',
  //   IS_MENU: true,
  //   PARENT: MenuItems.OPERATIONS,
  //   CHILDREN: {
  //     LIST: {
  //       PATH: '/list',
  //       FULL_PATH: '/cheques/list',
  //       NAME: 'List',
  //       IS_MENU: false
  //     },
  //     EDIT: {
  //       PATH: '/edit',
  //       FULL_PATH: '/cheques/edit',
  //       NAME: 'Edit',
  //       IS_MENU: false,
  //       NEW_LABEL: true
  //     }
  //   }
  // },
  // RENEWALES_MODULE: {
  //   PATH: '/renewales',
  //   FULL_PATH: '/renewales',
  //   DISPLAY_NAME: 'Renewales',
  //   NAME: 'renewales',
  //   IS_MENU: true,
  //   PARENT: MenuItems.OPERATIONS,
  //   CHILDREN: {
  //     LIST: {
  //       PATH: '/list',
  //       FULL_PATH: '/renewales/list',
  //       NAME: 'List',
  //       IS_MENU: false
  //     },
  //     EDIT: {
  //       PATH: '/edit',
  //       FULL_PATH: '/renewales/edit',
  //       NAME: 'Edit',
  //       IS_MENU: false,
  //       NEW_LABEL: true
  //     }
  //   }
  // },
  // PAYMENTS_MODULE: {
  //   PATH: '/payments',
  //   FULL_PATH: '/payments',
  //   DISPLAY_NAME: 'Payments',
  //   NAME: 'payments',
  //   IS_MENU: true,
  //   ICON: MenuIcons.PAYMENTS_MODULE,
  //   CHILDREN: {
  //     LIST: {
  //       PATH: '/list',
  //       FULL_PATH: '/payments/list',
  //       NAME: 'List',
  //       IS_MENU: false
  //     },
  //     EDIT: {
  //       PATH: '/edit',
  //       FULL_PATH: '/payments/edit',
  //       NAME: 'Edit',
  //       IS_MENU: false,
  //       NEW_LABEL: true
  //     }
  //   }
  // },
  LEADS_DASHBOARD_MODULE: {
    PATH: '/leads/dashboard',
    FULL_PATH: '/leads/dashboard',
    DISPLAY_NAME: 'Dashboard',
    NAME: 'leads/dashboard',
    IS_MENU: true,
    PERMITTED_ROLES: [UserRole.ADMIN, UserRole.DIGITAL_SALES_LEAD, UserRole.DIGITAL_SALES_MANAGER],
    PARENT: MenuItems.LEADS
  },
  LEADS_MODULE: {
    PATH: '/leads',
    FULL_PATH: '/leads',
    DISPLAY_NAME: 'Leads',
    NAME: 'leads',
    IS_MENU: true,
    PERMITTED_ROLES: [
      UserRole.ADMIN,
      UserRole.DIGITAL_SALES_AGENT,
      UserRole.DIGITAL_SALES_SENIOR_AGENT,
      UserRole.DIGITAL_SALES_LEAD,
      UserRole.DIGITAL_SALES_MANAGER
    ],
    PARENT: MenuItems.LEADS,
    CHILDREN: {
      DASHBOARD: {
        PATH: '/leads/dashboard',
        FULL_PATH: '/leads/dashboard',
        NAME: 'List',
        IS_MENU: true
      },
      LIST: {
        PATH: '/leads',
        FULL_PATH: '/leads/list',
        NAME: 'List',
        IS_MENU: true
      },
      DETAILS: {
        PATH: '/leads/details',
        FULL_PATH: '/leads/details/:id',
        NAME: 'List',
        IS_MENU: false
      },
      ADD: {
        PATH: '/add',
        FULL_PATH: '/leads/add',
        NAME: 'Add',
        IS_MENU: false
      },
      EDIT: {
        PATH: '/leads/edit',
        FULL_PATH: '/leads/edit/:id',
        NAME: 'edit',
        IS_MENU: false
      }
    }
  },
  FINANCE_APPLICATIONS_DASHBOARD_MODULE: {
    PATH: '/finance-applications/dashboard',
    FULL_PATH: '/finance-applications/dashboard',
    DISPLAY_NAME: 'Applications Dashboard',
    NAME: 'finance-applications/dashboard',
    IS_MENU: true,
    PERMITTED_ROLES: [
      UserRole.ADMIN,
      UserRole.DIGITAL_SALES_MANAGER,
      UserRole.RETAIL_SALES_MANAGER,
      UserRole.CORPORATE_SALES_MANAGER
    ],
    PARENT: MenuItems.CUSTOMERS
  },
  CUSTOMERS_MODULE: {
    PATH: '/customers',
    FULL_PATH: '/customers',
    DISPLAY_NAME: 'Profiles',
    NAME: 'customers',
    IS_MENU: true,
    PERMITTED_ROLES: [
      UserRole.ADMIN,
      UserRole.DIGITAL_SALES_AGENT,
      UserRole.DIGITAL_SALES_SENIOR_AGENT,
      UserRole.DIGITAL_SALES_LEAD,
      UserRole.DIGITAL_SALES_MANAGER,
      UserRole.RETAIL_SALES_AGENT,
      UserRole.RETAIL_SALES_SENIOR_AGENT,
      UserRole.RETAIL_SALES_LEAD,
      UserRole.RETAIL_SALES_MANAGER,
      UserRole.CORPORATE_SALES_AGENT,
      UserRole.CORPORATE_SALES_SENIOR_AGENT,
      UserRole.CORPORATE_SALES_LEAD,
      UserRole.CORPORATE_SALES_MANAGER,
      UserRole.FINANCE_OFFICER
    ],
    PARENT: MenuItems.CUSTOMERS,
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/customers/list',
        NAME: 'List',
        IS_MENU: true
      },
      DETAILS: {
        PATH: '/customers/customer-details',
        FULL_PATH: '/customers/customer-details/:id',
        NAME: 'List',
        IS_MENU: false
      },
      ADD: {
        PATH: '/add',
        FULL_PATH: '/customers/add',
        NAME: 'Add',
        IS_MENU: false
      },
      EDIT: {
        PATH: '/customers/edit',
        FULL_PATH: '/customers/edit/:id',
        NAME: 'edit',
        IS_MENU: false
      },
      FINANCE_APPLICATION_DETAILS: {
        PATH: 'customers/customer-details/finance-application',
        FULL_PATH: '/customers/customer-details/:customerId/finance-application/:applicationId',
        NAME: 'List finance applications',
        IS_MENU: false
      },
      ADD_FINANCE_APPLICATION: {
        PATH: 'customers/customer-details/:customerId/finance-application/add',
        FULL_PATH: '/customers/customer-details/:customerId/finance-application/add',
        NAME: 'Add finance application',
        IS_MENU: false
      },
      EDIT_FINANCE_APPLICATION: {
        PATH: 'customers/customer-details/:customerId/finance-application/edit',
        FULL_PATH:
          '/customers/customer-details/:customerId/finance-application/edit/:applicationId',
        NAME: 'Edit finance application',
        IS_MENU: false
      }
    }
  },

  FINANCE_APPLICATIONS_MODULE: {
    PATH: '/finance-applications',
    FULL_PATH: '/finance-applications',
    DISPLAY_NAME: 'Finance Applications',
    NAME: 'finance-applications',
    IS_MENU: true,
    PERMITTED_ROLES: [
      UserRole.ADMIN,
      UserRole.DIGITAL_SALES_AGENT,
      UserRole.DIGITAL_SALES_SENIOR_AGENT,
      UserRole.DIGITAL_SALES_LEAD,
      UserRole.DIGITAL_SALES_MANAGER,
      UserRole.RETAIL_SALES_AGENT,
      UserRole.RETAIL_SALES_SENIOR_AGENT,
      UserRole.RETAIL_SALES_LEAD,
      UserRole.RETAIL_SALES_MANAGER,
      UserRole.CORPORATE_SALES_AGENT,
      UserRole.CORPORATE_SALES_SENIOR_AGENT,
      UserRole.CORPORATE_SALES_LEAD,
      UserRole.CORPORATE_SALES_MANAGER,
      UserRole.FINANCE_OFFICER
    ],
    PARENT: MenuItems.CUSTOMERS,
    CHILDREN: {
      DASHBOARD: {
        PATH: '/dashboard',
        FULL_PATH: '/finance-applications/dashboard',
        NAME: 'Finance Applications Dashboard',
        IS_MENU: true,
        PERMITTED_ROLES: [
          UserRole.ADMIN,
          UserRole.DIGITAL_SALES_MANAGER,
          UserRole.RETAIL_SALES_MANAGER,
          UserRole.CORPORATE_SALES_MANAGER
        ]
      },
      LIST: {
        PATH: '/list',
        FULL_PATH: '/finance-applications/list',
        NAME: 'List',
        IS_MENU: true
      },
      DETAILS: {
        PATH: '/finance-applications/details',
        FULL_PATH: '/finance-applications/details/:id',
        NAME: 'List',
        IS_MENU: false
      },
      ADD: {
        PATH: '/add',
        FULL_PATH: '/finance-applications/add',
        NAME: 'Add',
        IS_MENU: false
      },
      EDIT: {
        PATH: '/finance-applications/edit',
        FULL_PATH: '/finance-applications/edit/:id',
        NAME: 'edit',
        IS_MENU: false
      }
    }
  },
  MERCHANT_MODULE: {
    PATH: '/merchants',
    FULL_PATH: '/merchants',
    DISPLAY_NAME: 'Merchants',
    NAME: 'merchants',
    IS_MENU: true,
    PERMITTED_ROLES: [
      UserRole.ADMIN,
      UserRole.E_COMMERCE_MANAGER,
      UserRole.E_COMMERCE_FINANCE,
      UserRole.E_COMMERCE_AUTO
    ],
    PARENT: MenuItems.PARTNERS,
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/merchants/list',
        NAME: 'List',
        IS_MENU: true
      },
      AUTO_DETAILS: {
        PATH: '/merchants/auto-details',
        FULL_PATH: '/merchants/auto-details/:id',
        NAME: 'List',
        IS_MENU: false
      },
      ADD: {
        PATH: '/add',
        FULL_PATH: '/merchants/add',
        NAME: 'Add',
        IS_MENU: false
      },
      EDIT: {
        PATH: '/merchants/edit',
        FULL_PATH: '/merchants/edit/:id',
        NAME: 'edit',
        IS_MENU: false
      }
    }
  },
  // SALESPERSONS_MODULE: {
  //   PATH: '/salespersons',
  //   FULL_PATH: '/salespersons',
  //   DISPLAY_NAME: 'Salespersons',
  //   NAME: 'salespersons',
  //   IS_MENU: true,
  //   PARENT: MenuItems.PARTNERS,
  //   CHILDREN: {
  //     LIST: {
  //       PATH: '/list',
  //       FULL_PATH: '/salespersons/list',
  //       NAME: 'List',
  //       IS_MENU: true
  //     },
  //     EDIT: {
  //       PATH: '/edit',
  //       FULL_PATH: '/salespersons/edit',
  //       NAME: 'Edit',
  //       IS_MENU: false,
  //       NEW_LABEL: true
  //     }
  //   }
  // },
  // CORPORATES_MODULE: {
  //   PATH: '/corporates',
  //   FULL_PATH: '/corporates',
  //   DISPLAY_NAME: 'Corporates',
  //   NAME: 'corporates',
  //   IS_MENU: true,
  //   PARENT: MenuItems.PARTNERS,
  //   CHILDREN: {
  //     LIST: {
  //       PATH: '/list',
  //       FULL_PATH: '/corporates/list',
  //       NAME: 'List',
  //       IS_MENU: true
  //     },
  //     EDIT: {
  //       PATH: '/edit',
  //       FULL_PATH: '/corporates/edit',
  //       NAME: 'Edit',
  //       IS_MENU: false,
  //       NEW_LABEL: true
  //     }
  //   }
  // },
  PRODUCTS_MODULE: {
    PATH: '/products',
    FULL_PATH: '/products',
    DISPLAY_NAME: 'Auto products',
    NAME: 'products',
    IS_MENU: true,
    PERMITTED_ROLES: [
      UserRole.ADMIN,
      UserRole.E_COMMERCE_MANAGER,
      UserRole.E_COMMERCE_AUTO,
      UserRole.E_COMMERCE_MANAGER
    ],
    PARENT: MenuItems.PRODUCTS,
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/products/list',
        NAME: 'List',
        IS_MENU: true
      },
      ADD_AUTO: {
        PATH: '/add/auto',
        FULL_PATH: '/products/add/auto',
        NAME: 'Add',
        IS_MENU: false
      },
      EDIT_AUTO: {
        PATH: '/products/edit/auto',
        FULL_PATH: '/products/edit/auto/:id',
        NAME: 'edit',
        IS_MENU: false
      }
    }
  },
  FINANCIAL_PRODUCTS_MODULE: {
    PATH: '/financial-products',
    FULL_PATH: '/financial-products',
    DISPLAY_NAME: 'Financial products',
    NAME: 'financial-products',
    IS_MENU: true,
    PERMITTED_ROLES: [
      UserRole.ADMIN,
      UserRole.E_COMMERCE_MANAGER,
      UserRole.E_COMMERCE_FINANCE,
      UserRole.E_COMMERCE_MANAGER,
      UserRole.DIGITAL_SALES_AGENT,
      UserRole.DIGITAL_SALES_SENIOR_AGENT,
      UserRole.DIGITAL_SALES_LEAD,
      UserRole.DIGITAL_SALES_MANAGER,
      UserRole.RETAIL_SALES_AGENT,
      UserRole.RETAIL_SALES_SENIOR_AGENT,
      UserRole.RETAIL_SALES_LEAD,
      UserRole.RETAIL_SALES_MANAGER,
      UserRole.CORPORATE_SALES_AGENT,
      UserRole.CORPORATE_SALES_SENIOR_AGENT,
      UserRole.CORPORATE_SALES_LEAD,
      UserRole.CORPORATE_SALES_MANAGER,
      UserRole.FINANCE_OFFICER,
      UserRole.OPERATION_MANAGER
    ],
    PARENT: MenuItems.PRODUCTS,
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/financial-products/list',
        NAME: 'FinList',
        IS_MENU: true
      },
      ADD_FINANCIAL_PRODUCT: {
        PATH: '/add/financial-products',
        FULL_PATH: '/financial-products/add',
        NAME: 'FinAdd',
        IS_MENU: false
      },
      EDIT_FINANCIAL_PRODUCT: {
        PATH: '/financial-products/edit',
        FULL_PATH: '/financial-products/edit/:id',
        NAME: 'FinEdit',
        IS_MENU: false
      }
    }
  },
  REPORTS_MODULE: {
    PATH: '/reports',
    FULL_PATH: '/reports',
    DISPLAY_NAME: 'Finance Applications Report',
    NAME: 'reports',
    IS_MENU: true,
    PERMITTED_ROLES: [
      UserRole.ADMIN,
      UserRole.RETAIL_SALES_MANAGER,
      UserRole.DIGITAL_SALES_MANAGER,
      UserRole.CORPORATE_SALES_MANAGER,
      UserRole.FINANCE_OFFICER
    ],
    PARENT: MenuItems.REPORTS,
    CHILDREN: {
      FINANCE_APPLICATIONS_REPORT_FILTER: {
        PATH: '/filter',
        FULL_PATH: '/reports/finance-application-report/filter',
        NAME: 'Finance Applications Report Generate',
        IS_MENU: true
      },
      FINANCE_APPLICATIONS_REPORT: {
        PATH: '/list',
        FULL_PATH: '/reports/finance-application-report/list',
        NAME: 'Finance Applications Report',
        IS_MENU: true
      }
    }
  },
  USER_MANAGEMENT_MODULE: {
    PATH: '/user-management',
    FULL_PATH: '/user-management',
    DISPLAY_NAME: 'Accounts',
    NAME: 'user-management',
    IS_MENU: true,
    PERMITTED_ROLES: [UserRole.ADMIN],
    PARENT: MenuItems.USERS,
    CHILDREN: {
      LIST: {
        PATH: '/user-management',
        FULL_PATH: '/user-management/list',
        NAME: 'List',
        IS_MENU: true
      },
      DETAILS: {
        PATH: '/user-management/details',
        FULL_PATH: '/user-management/details/:id',
        NAME: 'List',
        IS_MENU: false
      },
      ADD: {
        PATH: '/add',
        FULL_PATH: '/user-management/add',
        NAME: 'Add',
        IS_MENU: false
      },
      EDIT: {
        PATH: '/user-management/edit',
        FULL_PATH: '/user-management/edit/:id',
        NAME: 'edit',
        IS_MENU: false
      }
    }
  },

  SALES_MANAGEMENT_MODULE: {
    PATH: '/sales-management',
    FULL_PATH: '/sales-management',
    DISPLAY_NAME: 'Teams',
    NAME: 'teams',
    IS_MENU: true,
    PERMITTED_ROLES: [UserRole.ADMIN],
    PARENT: MenuItems.SALES,
    CHILDREN: {
      TEAMS_LIST: {
        PATH: '/sales-management/teams',
        FULL_PATH: '/sales-management/teams/list',
        NAME: 'List',
        IS_MENU: true
      },
      TEAMS_ADD: {
        PATH: '/sales-management/teams/add',
        FULL_PATH: '/sales-management/teams/add',
        NAME: 'Add',
        IS_MENU: false
      },
      TEAMS_EDIT: {
        PATH: '/sales-management/teams/edit',
        FULL_PATH: '/sales-management/teams/edit',
        NAME: 'edit',
        IS_MENU: false
      },
      TEAM_MEMBER_DETAILS: {
        PATH: '/sales-management/teams/member',
        FULL_PATH: '/sales-management/teams/member/:id',
        NAME: 'member-details',
        IS_MENU: false
      },
      KPIS: {
        PATH: '/sales-management/teams/kpis',
        FULL_PATH: '/sales-management/teams/kpis',
        NAME: 'kpis',
        IS_MENU: true
      },
      COMMISSION_SCHEME: {
        PATH: '/commission-scheme',
        FULL_PATH: '/sales-management/commission-scheme',
        NAME: 'commission-scheme',
        IS_MENU: true
      },
      ARCHIVE: {
        PATH: '/archive',
        FULL_PATH: '/sales-management/archive',
        NAME: 'archive',
        IS_MENU: true
      }
    }
  },
  COMMISSION_SCHEME_MODULE: {
    PATH: '/sales-management/commission-scheme',
    FULL_PATH: '/sales-management/commission-scheme',
    DISPLAY_NAME: 'Commission scheme',
    NAME: 'commission-scheme',
    IS_MENU: true,
    PERMITTED_ROLES: [UserRole.ADMIN],
    PARENT: MenuItems.SALES
  },
  ARCHIVE_MODULE: {
    PATH: '/sales-management/archive',
    FULL_PATH: '/sales-management/archive',
    DISPLAY_NAME: 'Archive',
    NAME: 'archive',
    IS_MENU: true,
    PERMITTED_ROLES: [UserRole.ADMIN],
    PARENT: MenuItems.SALES,
    CHILDREN: {
      LIST: {
        PATH: '/sales-management/archive',
        FULL_PATH: '/sales-management/archive/list',
        NAME: 'List',
        IS_MENU: true
      },
      DETAILS: {
        PATH: '/sales-management/archive',
        FULL_PATH: '/sales-management/archive/:id',
        NAME: 'details',
        IS_MENU: false
      }
    }
  },
  BRANDS_MODULE: {
    PATH: '/configuration/brands',
    FULL_PATH: '/configuration/brands',
    DISPLAY_NAME: 'Auto brands',
    NAME: 'brands',
    IS_MENU: true,
    PERMITTED_ROLES: [UserRole.ADMIN, UserRole.E_COMMERCE_MANAGER, UserRole.E_COMMERCE_AUTO],
    PARENT: MenuItems.SETTINGS,
    CHILDREN: {
      BRANDS_LIST: {
        PATH: '/configuration/brands/list',
        FULL_PATH: '/configuration/brands/list',
        NAME: 'BrandsList',
        IS_MENU: true,
        PERMITTED_ROLES: [UserRole.ADMIN, UserRole.E_COMMERCE_MANAGER, UserRole.E_COMMERCE_AUTO]
      }
    }
  }
  // ACCOUNT_SETTINGS_MODULE: {
  //   PATH: '/settings',
  //   FULL_PATH: '/setting',
  //   DISPLAY_NAME: 'Account Settings',
  //   IS_MENU: false,
  //   NAME: 'account settings'
  //   // ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/settings.png`
  // },
  // USER_PROFILE_MODULE: {
  //   PATH: '/user-profile',
  //   FULL_PATH: '/user-profile',
  //   DISPLAY_NAME: 'Profile',
  //   IS_MENU: false,
  //   NAME: 'user profile'
  //   // ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/user-profile.png`
  // },
  // LOGOUT_MODULE: {
  //   PATH: '/logout',
  //   FULL_PATH: '/logout',
  //   DISPLAY_NAME: 'Logout',
  //   IS_MENU: false,
  //   NAME: 'logout'
  //   // ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/logout.png`
  // }
};
